import React, {useContext, useEffect, useRef, useState} from 'react';
import videoUploadProgress from "./requestingMediaFunctions/videoUploadProgress";
import ImageMfl from "../viewMedia/ImageMfl";
import fetchEventsVideoFile from "../../../api/events/fetchEventsVideoFile";
import videoPlay from "../../../svgs/video_play.png";
import deleteNotificationMediaQueue from "../../../api/notifications/deleteNotificationMediaQueue";
import mainContext from "../../contexts/mainContext";
import {uploadTrackStageDisplay} from "./requestingMediaFunctions/uploadTrackStageDisplay";
import {Knob} from "primereact/knob";
import VideoThumb from "../viewMedia/videoThumb";
import FetchMediaToken from "../../../api/media/fetchMediaToken";
import {API_URL} from "../../../Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faArrowsRotate, faArrowRight} from "@fortawesome/pro-light-svg-icons";
import postVideoRerequest from "../../../api/media/postVideoRerequest";
import {Tooltip} from "primereact/tooltip";
import {faCircleInfo} from "@fortawesome/pro-light-svg-icons";
import {formatDateOnly, formatTimeOnly, fullDurationFromSeconds} from "../../../functions/formatting/formatDate";


const VideoUploadProgressTrackerV2 = ({upload, page, getMediaQueue, deleteMediaQueue}) => {

    const {handleEvent, refreshEventsForJourney, journey, handleRefreshNotifications, vehicles, event} = useContext(mainContext)

    const [uploadProgress, setUploadProgress] = useState({stage: 'pending', percentage: 0});
    const [theDevice, setTheDevice] = useState();
    const [complete, setComplete] = useState(false);
    const [theEvent, setTheEvent] = useState(event);
    const [mounted, setMounted] = useState(true);
    const [contentSrc, setContentSrc] = useState();
    const [stageOfUpload, setStageOfUpload] = useState('uploading');
    const [uploadDetails, setUploadDetails] = useState('Awaiting start');

    const intervalRef = useRef();


    const retryUpload = async () => {
        const data = {
            dn: upload.dn,
            mediaId: upload._id,
            requestType: 'keep_trying'
        }

        await postVideoRerequest(data);
    }



    useEffect( async () => {
        if (upload && vehicles && mounted) {


            let theProgress = await videoUploadProgress(upload);



            if (theProgress){
                if (theProgress?.stage === 'error'){


                    // if(page !== 'trackModal'){
                    //     deleteNotificationMediaQueue(upload).then(() => {
                    //         getMediaQueue()
                    //     });
                    // }


                } else {
                    if (theProgress?.stage === 'processing' && (theProgress?.percentage) > 99){

                        // deleteNotificationMediaQueue(upload).then(() => {
                        //     getMediaQueue()
                        // });

                        clearInterval(intervalRef.current);
                        setUploadProgress(theProgress);
                        setUploadDetails('Finalising upload');
                        setComplete(true)

                    } else {
                        // waiting for upload
                        setUploadProgress(theProgress);
                        setUploadDetails('Please wait');
                        intervalRef.current = setInterval(async () => {
                            let theProgress = await videoUploadProgress(upload);
                            setUploadProgress(theProgress);
                        }, 5000);

                    }
                }
            } else {
                clearInterval(intervalRef.current);
            }

        }

        return () => {
            clearInterval(intervalRef.current);
        }
    }, [upload, vehicles])





    useEffect( async () => {
        setStageOfUpload(uploadProgress.stage)

        const intDevice =
            vehicles?.features.filter(v => v?.properties?.dn === upload?.dn );



        if (intDevice?.[0]?.properties?.deviceStatus === 'offline' ) {
            setStageOfUpload('pending');
            setUploadDetails('Device offline')
            clearInterval(intervalRef.current);
            return;
        }

        // if failed for any reason stop the interval
        if ((uploadProgress?.details?.failed || uploadProgress?.details?.unavailable)){
            clearInterval(intervalRef.current);
            const footageNotOnDeviceErrCount = uploadProgress?.details?.requestResponse.filter(resp => resp.err === '6');
            setStageOfUpload('failed');

            if (footageNotOnDeviceErrCount > 9 && uploadProgress?.details?.failed) {
                setUploadDetails('Footage not on device')
                return
            }
            if (uploadProgress?.details?.requestResponse?.length > 9) {
                setUploadDetails('Max attempts exceeded')
            }
            return;

        }


        if (uploadProgress.stage === 'processing' && uploadProgress.percentage === 100){
            clearInterval(intervalRef.current);
            setComplete(true);
            if (upload.st){
                const r = await fetchEventsVideoFile({
                    dn: upload.data.dn,
                    file: upload.data.filename
                });

                if(JSON.stringify(theEvent) !== JSON.stringify(r?.[0]) && r?.[0]){
                    setTheEvent(r?.[0]);
                }
            }

            if(page === 'trackModal' && mounted){
                handleRefreshNotifications();
            } else if (page === 'notifications'){
                deleteMediaQueue(upload)
            }
            return;
        }

    }, [uploadProgress]);



    useEffect(async () => {
        if (complete){
            const file = upload.file ? upload.file : upload.filename
            const token = await FetchMediaToken(file);
            setContentSrc(API_URL +'/view-video/' + file + '?mt=' + token?.access_token);

            if(page !== 'trackModal'){
                deleteNotificationMediaQueue(upload).then(() => {
                    getMediaQueue()
                });
            }
        }
    }, [complete])

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
            setMounted(false)
        }
    }, [])



    return (
        <React.Fragment>
            {complete ?
                <div style={{position: 'relative'}}>
                    <video src={contentSrc} controls={false} style={{width: '100%'}}></video>

                    <img src={videoPlay} onClick={() => {
                        handleEvent(theEvent)
                    }} style={{
                        position: 'absolute',
                        width: '100px',
                        cursor: 'pointer',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}/>
                </div>


            :
                <React.Fragment>
                    <Knob value={uploadProgress?.percentage?.toFixed(0)} valueTemplate={'{value}%'}/>

                    <div style={{marginTop: '10px'}}>
                        {uploadTrackStageDisplay(stageOfUpload)}<br />
                        {uploadDetails}<br />
                        {/*<FontAwesomeIcon icon={faCircleInfo} style={{fontSize: '18px'}} className={"info" + upload?._id}*/}
                        {/*                 data-pr-position="bottom"/>*/}
                        {/*<Tooltip target={".info" + upload?._id}>*/}
                        {/*    {uploadProgress?.details?.st ?*/}
                        {/*        <div>*/}
                        {/*            Request Date:*/}
                        {/*            &nbsp;{formatDateOnly(uploadProgress?.details?.requestDate)} <br />*/}
                        {/*            Request Time:*/}
                        {/*            &nbsp;{formatTimeOnly(uploadProgress?.details?.requestDate)} <br />*/}
                        {/*            Media Date:*/}
                        {/*            &nbsp;{formatDateOnly(uploadProgress?.details?.st)} <br />*/}
                        {/*            Media Time:*/}
                        {/*            &nbsp;{formatTimeOnly(uploadProgress?.details?.st)}&nbsp;*/}
                        {/*            <FontAwesomeIcon icon={faArrowRight} />*/}
                        {/*            &nbsp;{formatTimeOnly(uploadProgress?.details?.et)}<br />*/}
                        {/*            Duration:&nbsp;{fullDurationFromSeconds((new Date(uploadProgress?.details?.et )- new Date(uploadProgress?.details?.st))/1000)}*/}

                        {/*            {uploadProgress?.details?.camPosition &&*/}
                        {/*                <span>*/}
                        {/*            <br />*/}
                        {/*            Camera:*/}
                        {/*                    &nbsp;{uploadProgress?.details?.camPosition}*/}
                        {/*        </span>*/}
                        {/*            }*/}
                        {/*            {uploadProgress?.details?.email &&*/}
                        {/*                <span>*/}
                        {/*            <br />*/}
                        {/*            Requested by:*/}
                        {/*                    &nbsp;{uploadProgress?.details?.email.substring(0,17)}*/}
                        {/*                    /!*TODO REPLACE WITH CSS*!/*/}
                        {/*                    {uploadProgress?.details?.email?.length > 17 &&*/}
                        {/*                        <span>...</span>*/}
                        {/*                    }*/}
                        {/*        </span>*/}
                        {/*            }*/}
                        {/*            <br/>Attempt count:&nbsp;{(uploadProgress?.details?.attemptCount + 1)}*/}

                        {/*        </div>*/}
                        {/*        :*/}
                        {/*        // hyperlapse progress here*/}
                        {/*        <div />*/}
                        {/*    }*/}
                        {/*</Tooltip>*/}
                    </div>

                </React.Fragment>
            }

            {/*{!complete && !upload?.startTimeHyper &&*/}
            {/*    <div style={{textAlign: 'center', marginTop: '10px'}}>*/}
            {/*        <span className={"deleteButton" + upload?._id} onClick={() => deleteMediaQueue(upload)}*/}
            {/*              data-pr-position="bottom">*/}
            {/*            <FontAwesomeIcon icon={faTrash}  style={{cursor: 'pointer', fontSize:"26px"}}/>*/}
            {/*        </span>*/}

            {/*        <Tooltip target={".deleteButton" + upload?._id}>Delete</Tooltip>*/}

            {/*        {(uploadDetails === 'Max attempts exceeded') &&*/}
            {/*            <span className={"retryButton" + upload?._id}  onClick={() => retryUpload(upload)} data-pr-position="bottom"*/}
            {/*                  style={{marginLeft: '5px'}}>*/}
            {/*            <FontAwesomeIcon icon={faArrowsRotate}  style={{cursor: 'pointer', fontSize:"26px"}}/>*/}
            {/*        </span>*/}
            {/*        }*/}

            {/*        <Tooltip target={".retryButton" + upload?._id}>Retry</Tooltip>*/}
            {/*    </div>*/}
            {/*}*/}
        </React.Fragment>

    )
}

export default VideoUploadProgressTrackerV2
