import React, {useContext, useEffect, useState} from 'react';
import mainContext from "../../../../contexts/mainContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {    faCarMirrors, faTrash} from "@fortawesome/pro-light-svg-icons";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import {Tooltip} from "primereact/tooltip";
import {faBuildings} from "@fortawesome/pro-regular-svg-icons";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import {fleetFromDn} from "../../../../../functions/formatting/fleetFromDn";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import EventClassificationIcon from "../cardComponents/eventClassificationIcon";
import MediaUploadInfoIcon from "./mediaUploadInfoIcon";
import VideoUploadProgressTracker from "../../../requestingMedia/videoUploadProgressTracker";
import deleteNotificationMediaQueue from "../../../../../api/notifications/deleteNotificationMediaQueue";
import {formatDateNotificationsTable} from "../../../../../functions/formatting/formatDate";
import indexContext from "../../../../contexts/indexContext";
import fetchEventsVideoFile from "../../../../../api/events/fetchEventsVideoFile";
import postVideoRerequest from "../../../../../api/media/postVideoRerequest";
import VideoUploadProgressTrackerV2 from "../../../requestingMedia/uploadProgressTrackerV2";
import StackedSeverityClassificationIcon from "../cardComponents/stackedSeverity&ClassificationIcon";
import VehicleLiveLocationV2 from "../../../vehicleList/vehicleLiveLocationV2";


const NotificationGridUpload = ({notification}) => {

    const {vehicles, handleEvent, handleVehicle} = useContext(mainContext);
    const {fleets, handleRefreshNotifications} = useContext(indexContext)



    const deleteMediaQueue = async (item) => {

        const data = {
            dn: item.dn,
            mediaId: item._id,
            requestType: 'cancel'
        }

        // await postVideoRerequest(data);
        await deleteNotificationMediaQueue(item);

        const ele = document.getElementById(item?._id);
        ele.parentNode.remove();

        handleRefreshNotifications();
    }


    const handleMediaClick = () => {
        if(notification.filename){
            fetchEventsVideoFile({
                dn: notification?.dn,
                file: notification.filename
            }).then(r => {
                handleEvent(r[0])
            });
        }
    }

    const handleClick = (e) => {
        e.stopPropagation();
        handleMediaClick(notification);
    }


    return (
        <React.Fragment>
            <div key={notification?._id}
                 style={{padding: '0px 10px 0px 10px', borderRadius: 'inherit', width: '100%', height: '100%'}}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '5px',
                    position: 'relative'
                }}>
                    <div style={{flex: 1, fontSize: '15px', fontWeight: 'bold'}}>
                        {formatDateNotificationsTable(notification.st)}
                        <br/>
                        <div style={{fontSize: '12px', fontWeight: 'normal', lineHeight: '15px'}}
                             onClick={(e) => {
                                 e.stopPropagation();
                                 handleVehicle(vehicles.features.filter(v => v.properties.dn === notification.dn)?.[0])
                             }} className={"reg" + notification._id} data-pr-position="bottom">
                            {regFromDn(vehicles, notification?.dn)}
                        </div>
                        <Tooltip target={".reg" + notification._id} id={'reg' + notification?._id}>
                            Click to filter by this vehicle
                        </Tooltip>
                    </div>

                    <StackedSeverityClassificationIcon event={notification} size={'25px'}/>
                    <div style={{
                        marginLeft: '5px',
                        display: 'inline-block',
                        textAlign: 'center',
                        borderRadius: '50%',
                        width: '25px',
                        height: '25px',
                        lineHeight: '25px',
                        color: 'white',
                        background: 'green'
                    }}>
                        1
                    </div>

                    {/*<div className="eventClassificationContainer surface-ground" style={{position:'absolute', right: 0, background:'#ffffff'}}>*/}
                    {/*    <div className="eventClassificationTitle">Details</div>*/}
                    {/*    <EventClassificationIcon event={{analysis: {eventType: 'video'}}} height='30px' />*/}
                    {/*    <MediaUploadInfoIcon notification={notification} />*/}
                    {/*</div>*/}

                </div>


                <div style={{textAlign: 'center', margin: '0 auto', position: 'relative'}}
                     onClick={handleClick}>
                    <VideoUploadProgressTrackerV2 upload={notification} vehicles={vehicles} handleEvent={handleEvent}
                                                  page={'notifications'}
                                                  deleteMediaQueue={deleteMediaQueue}
                                                  handleRefreshNotifications={handleRefreshNotifications}/>
                </div>


                <div style={{
                    textAlign: 'left', position: 'relative',
                    zIndex: '10', margin: '0 auto', borderRadius: '2px', padding: '10px 10px 0px 10px'
                }}
                     onClick={(e) => {
                         //e.stopPropagation();
                         //viewJourney()
                     }}>


                    {notification?.geometry ?
                        <VehicleLiveLocationV2 d={{lat: notification?.geometry?.[1],
                            lng: notification?.geometry?.[0]}} icon={false}/>
                    :
                        <span>Calendar request</span>

                    }



                </div>


                <div style={{
                    textAlign: 'left',
                    position: 'relative',
                    zIndex: '10',
                    margin: '0 auto',
                    borderRadius: '2px',
                    marginTop: '2px',
                    padding: '0px 10px 10px 10px'
                }}
                >
                    {friendlyFleetName(notification?.fleetId, fleets)}
                </div>


            </div>
        </React.Fragment>
    )
}
export default NotificationGridUpload
